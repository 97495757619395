import naja from "naja";

class SelectChangeHandler {
    initialize(naja) {
        window.addEventListener('DOMContentLoaded', this.setupSelectChange.bind(this));
    }

    setupSelectChange() {
        $(document).on('change', '.select-change', function(event) {
            const $select = $(this);
            const endpoint = $select.data('endpoint'); // Očekáváme atribut data-endpoint na <select>
            const selectedValue = $select.val();

            if (!endpoint) {
                console.error('Endpoint is not defined for select-change element');
                return;
            }

            // Provede AJAX požadavek na backend
            $.ajax({
                type: 'POST',
                url: endpoint,
                data: { value: selectedValue },
                success: function (data) {
                    // Předpokládáme, že Nette vrací snippety
                    naja.snippetHandler.updateSnippets(data.snippets || {});
                },
                error: function (xhr, status, error) {
                    console.error('Error during AJAX request:', error);
                }
            });
        });
    }
}

const selectChangeHandler = new SelectChangeHandler();
selectChangeHandler.initialize(naja);

export default SelectChangeHandler;
