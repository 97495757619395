import naja from "naja";
document.addEventListener('DOMContentLoaded', () => {
    initStoreDevice();
});

function initStoreDevice() {
    const isStoreDevice = $('#frm-deviceTabs-tabContent-zakladniinformace-tabControl-editForm');
    if (!isStoreDevice.length) {
        return;
    }

    const storeDevice = new StoreDevice();
    storeDevice.init();

    naja.addEventListener('complete', () => {
        storeDevice.init();
    });
}

class StoreDevice {
    purchaseDateId = 'frm-deviceTabs-tabContent-zakladniinformace-tabControl-editForm-purchaseDate';
    serialSelectId = 'frm-deviceTabs-tabContent-zakladniinformace-tabControl-editForm-serialNumberSelect';
    serialId = 'frm-deviceTabs-tabContent-zakladniinformace-tabControl-editForm-serialNumber';

    init() {
        $('#'+this.purchaseDateId).change(this.setPurchaseDate.bind(this));

        const serial = $('#' + this.serialSelectId);
        serial.change(this.setPurchaseDate.bind(this));
        serial.prop('selectedIndex', 0); // Select the first option
        serial.trigger('change');        // Trigger the change event
             // Attach the event handler

        $('input[disabled]').each(function() {
            $(this).removeAttr('disabled').attr('readonly', true);
        });
    }
    setPurchaseDate(event)
    {
        const select = $(event.target);
        const selectedOption = select.find(':selected');
        const price = selectedOption.data('price');
        const date = selectedOption.data('date');
        select.closest('form').find('input[name=datePurchase]').val(date);
        select.closest('form').find('input[name=price]').val(price);
        if (select.attr('id') == this.serialSelectId) {
            $('#'+this.serialId).val(select.val());
        }
        console.log({ price, date });
    }
}
